import { i18n } from "@/plugins/i18n";
import { axiosInstance } from "@/plugins/axios";

export const languages = {
  // cat: "Català",
  //ch: "中國人",
  en: "English",
  es: "Español",
  //fr: "Français",
  //it: "Italiano",
  //pt: "Português",
};

const loadedLanguages = [
  localStorage.getItem("lang") || navigator.language.split("-")[0],
];
export const supportedLang = Object.keys(languages);

function setI18nLanguage(lang) {
  localStorage.setItem("lang", lang);
  i18n.locale = lang;
  axiosInstance.defaults.headers["Accept-Language"] = lang;
  document.querySelector("html")?.setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (!supportedLang.includes(lang))
    return Promise.reject("Language not supported.");

  if (i18n.locale !== lang && loadedLanguages.includes(lang))
    return Promise.resolve(setI18nLanguage(lang));
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/assets/locales/${lang}.js`
  )
    .then((messages) => {
      loadedLanguages.push(lang);
      i18n.setLocaleMessage(lang, messages.default);
      return setI18nLanguage(lang);
    })
    .catch(() => {
      setI18nLanguage("en");
      return Promise.reject("Language not supported.");
    });
}
